@import url('https://fonts.googleapis.com/css2?family=Arizonia&display=swap');

$primaryColor: #091292 !important;
$primaryColorLight: #8d81a5;

.nav-color {
  background-color: #fff;
  z-index: 2;
  font-size: 19px;
}

.text-boldest {
  font-weight: 900;
}

.color-custom-blue {
  color: $primaryColor;
}

.color-custom-grey {
  color: $primaryColorLight;
}

.bg-custom-blue {
  background-color: $primaryColor;
}

.nav-link {
  min-width: 100px;
  &:hover {
    font-weight: bold;
  }
}

.skills-img {
  width: 80px;
  transition: width 0.15s;
  transition-timing-function: ease-in-out;
  cursor: pointer;
  &:hover {
    width: 90px;
    filter: none;
  }
}

.justify-content-evenly {
  justify-content: space-evenly;
}

/* Projects page */

.proj-image {
  border-radius: 20px;
}

.proj-opacity {
  animation: opacityChange 2s;
}

/* Contact page */

.text-link,
.text-link:hover {
  color: #fff;
  text-decoration: none;
}

.contact-btns span {
  max-width: 0;
  -webkit-transition: max-width 1s;
  transition: max-width 1s;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
}

.contact-btns:hover span {
  max-width: 7rem;
}

.contact-btns:hover span::before {
  content: '|';
  padding-left: 5px;
  padding-right: 5px;
  color: #ffffff;
}

.particle-container {
  position: relative;
  background-color: #fff;
  z-index: -1;
  // width: 100vw;
  /* height: 90vh; */
}

.color-name-blue {
  color: #000a94 !important;
}

.icon-card {
  height: 100px;
  width: 90px;
}

.contact-img {
  width: 35%;
}

.logo-img {
  width: 85%;
  height: 50px;
}

.contact-card,
.skill-icons-container {
  animation: slidein 1.5s;
}

.proj-slide {
  animation: slidein 2s;
}

.contact-card:hover {
  cursor: pointer;
  box-shadow: 1px 1px 7px grey !important;
}

.contact .card {
  border: none !important;
}

.about-text {
  letter-spacing: 1px;
  font-size: 18px;
  color: #727070;
}

.vertical-timeline::before {
  background: #bebebe !important;
}

.about-me-block {
  font-family: Fira Sans, sans-serif;
  font-weight: 700;
  font-size: 5.5rem;
  line-height: 120px;
  opacity: 0.1;
  color: #727070;
  text-transform: uppercase;
  text-align: right;
}

.skill-block {
  font-family: Fira Sans, sans-serif;
  font-size: 5.5rem;
  line-height: 100px;
  opacity: 0.4;
  color: #091292;
  text-transform: uppercase;
  text-align: center;
}

.timeline-work-icon {
  left: 26px !important;
}

.timeline-grad-icon {
  left: 23px !important;
}

.home-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.resume-btn {
  &:hover {
    background-image: linear-gradient(to top right, $primaryColor, #2631d4);
  }
}

.button {
  background-color: white;
  color: #191e69;
  border: 2px solid $primaryColor;
  text-align: center;
  padding: 16px 32px;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    color: white;
    background-color: $primaryColor;
  }
}

.button-cv {
  padding: 8px 16px;
}

.navbar-menu {
  border: none;
}

.icon-button {
  border: none;

  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  /* border: 1px solid transparent; */
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.dashed-border {
  border-style: dashed;
}

.programmer {
  width: 50%;
}

.page-not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  font-weight: bold;
  margin-top: 13%;
}

@keyframes slidein {
  from {
    margin-top: 10%;
    opacity: 0;
  }
  to {
    margin-top: 0%;
    opacity: 1;
  }
}

@keyframes slideout {
  from {
    margin-top: -2%;
    opacity: 0;
  }
  to {
    margin-top: 0%;
    opacity: 1;
  }
}

@keyframes opacityChange {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Media queries */

@media only screen and (min-width: 320px) and (max-width: 1168px) {
  .timeline-work-icon {
    left: 16px !important;
  }

  .timeline-grad-icon {
    left: 13px !important;
  }

  .programmer {
    width: 100%;
  }
  .page-not-found {
    margin-top: 22%;
  }
}

@media only screen and (min-width: 768px) {
  .nav-slide {
    animation: slideout 1.5s;
  }
}

@media only screen and (max-width: 768px) {
  .skill-icons-align {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
  }

  .icon-card {
    width: 60px;
  }
}

@media only screen and (max-width: 768px) {
  .home-content {
    justify-content: center;
    text-align: center;
  }
}
